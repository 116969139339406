import React, { Component } from 'react';
import './style.css'
import logo from './assets/images/logo.svg'
import side from './assets/images/side-1.svg'

import {Helmet} from "react-helmet";

// import logo from '' 
class Main extends Component {


    

    render() {
        

        
            

        return (
            <div>

                
                    <body>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <img src={logo} alt="TeleGalsa Logo" />
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="#">
                About
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                Contact us
              </a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                English
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#">
                  Action
                </a>
                <a class="dropdown-item" href="#">
                  Another action
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>

      <div class="main-container">
        <div class="forms-container">
          <div class="signin-signup">
            <form action="#" class="sign-in-form">
              <h2 class="title">Sign in </h2>
              <div class="form-group container">
                <label for="l-username">Email Addrres</label>
                <input
                  id="l-username"
                  type="text"
                  placeholder="example@gmail.com"
                  required
                  class="form-control"
                />
              </div>
              <div class="form-group mt-4 container">
                <label for="l-password">Password</label>
                <input
                  id="l-password"
                  type="password"
                  placeholder="*************"
                  required
                  class="form-control"
                />
              </div>

              <div class="form-check ">
                <input id="l-remeber" type="checkbox" />
                <label for="l-remeber">Remember Me</label>
              </div>
              <input type="submit" value="Next" class="bbtn solid mt-4" />
            </form>

            <form class="sign-up-form">
              <h2 class="title">Sign up</h2>

              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="s-fname">First Name</label>
                    <input
                      id="s-fname"
                      type="text"
                      placeholder="First Name"
                      required
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label for="s-lname">Last Name</label>
                    <input
                      id="s-lname"
                      type="text"
                      placeholder="Last Name"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="s-mail">Email Address</label>
                <input
                  name="s-mail"
                  type="email"
                  placeholder="example@gmail.com"
                  required
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label for="s-password">Password</label>
                <input
                  name="s-password"
                  type="password"
                  placeholder="***********"
                  required
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label for="s-repassword">Confirm Password</label>
                <input
                  name="s-repassword"
                  type="password"
                  placeholder="***********"
                  required
                  class="form-control"
                />
              </div>

              <input type="submit" value="Next" class="bbtn solid mt-4" />
            </form>
          </div>
        </div>

        <div class="panels-container">
          <div class="panel left-panel">
            <div class="content">
              <h3>TeleGlasa Sign in Form</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Debitis, eos eius quaerat, ad ducimus quia porro sit perferendis
                possimus sapiente magni, quidem odio doloribus autem illo maxime
                id aut veritatis.
              </p>
              <button class="bbtn" id="sign-up-btn">
                Sign up
              </button>
            </div>
            <img
              src={side}
              height="400"
              width="400"
              class="image"
              alt=""
            />
          </div>

          <div class="panel right-panel">
            <div class="content">
              <h3>TeleGalsa Sign up Form</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore
                nulla maxime exercitationem vitae animi quaerat veritatis
                debitis quidem, consectetur incidunt neque sed doloremque. Illum
                perspiciatis mollitia optio eos quod omnis.
              </p>
              <button class="bbtn" id="sign-in-btn">
                Sign in
              </button>
            </div>
            <img
              src="img/side-1.svg"
              height="400"
              width="400"
              class="image"
              alt=""
            />
          </div>
        </div>
      </div>

      <script src="js/app.js"></script>
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-A3rJD856KowSb7dwlZdYEkO39Gagi7vIsF0jrRAoQmDKKtQBHUuLZ9AsSv4jD4Xa"
        crossorigin="anonymous"
      ></script>
    </body>
                
            </div>
        );
    }
}

export default Main;